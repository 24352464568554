import {useMemo} from 'react';
import UAParser from 'ua-parser-js';

type IUseUserAgentReturn = Pick<UAParser.IResult, 'os' | 'browser' | 'device'>;

export default function useUserAgent() {
  return useMemo<IUseUserAgentReturn | null>(() => {
    try {
      const uaParser = new UAParser.UAParser();
      return {
        os: uaParser.getOS(),
        browser: uaParser.getBrowser(),
        device: uaParser.getDevice(),
      };
    } catch (err) {
      console.error(err);
      return null;
    }
  }, []);
}
