import {GanttStatic} from 'dhtmlx-gantt';
import {FC, memo, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import CtrlBtnOption from 'shared/components/CoreNewUI/CtrlBtnOption/CtrlBtnOption';
import Icon from 'shared/components/Icon';
import {GANTT_PREFERENCES_KEY, TasksViewMode} from 'shared/constants/common';
import {IconsMap} from 'shared/constants/icons';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useLocalStorage} from 'shared/hooks/useLocalStorage';
import {TaskColorMode} from 'shared/models/task/task';
import {normalizeI18Key} from 'shared/utils/normalizeI18Key';
import {RootState} from 'store';

type Props = {
  gantt: GanttStatic;
};

const ColorTasksModeBtn: FC<Props> = ({gantt}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {viewMode} = useFilterContext();
  const worker = useSelector((state: RootState) => state.profile.worker);
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode, worker}});
  const mixpanelEvent = mixpanel.events.tasks.toolbar.colorMode;

  const {t} = useTranslation('gantt');

  const [getValueFromLS, storeValueToLS] = useLocalStorage<TaskColorMode>({
    key: GANTT_PREFERENCES_KEY,
    path: `byProject.${projectId}.colorTasksMode`,
    defaultValue: null,
    enabled: !!projectId,
  });

  const [selected, setSelected] = useState<TaskColorMode>(getValueFromLS() || TaskColorMode.COMPANY);
  const updateValue = (newValue: TaskColorMode) => {
    if (newValue !== selected && isValidColorMode(newValue)) {
      mixpanel.track(mixpanelEvent(newValue));
      storeValueToLS(newValue);
      setSelected(newValue);
      gantt.taskColorMode = newValue;
      gantt.dRender();
    }
  };

  const isValidColorMode = useCallback(
    (mode: TaskColorMode): boolean => {
      if (mode === TaskColorMode.LABOR && viewMode !== TasksViewMode.lookahead) {
        return false;
      }
      return true;
    },
    [viewMode],
  );

  useEffect(() => {
    const storedValue = getValueFromLS();
    const validValue = isValidColorMode(storedValue) ? storedValue : TaskColorMode.COMPANY;
    setSelected(validValue);
    storeValueToLS(validValue);
    gantt.taskColorMode = validValue;
    gantt.dRender();
  }, [viewMode, getValueFromLS, storeValueToLS, isValidColorMode, gantt]);

  const getTranslatedColorMode = (mode: TaskColorMode): string => {
    switch (mode) {
      case TaskColorMode.COMPANY:
        return t('toolbar.tasksColorMode.options.company');
      case TaskColorMode.STATUS:
        return t('toolbar.tasksColorMode.options.status');
      case TaskColorMode.LABOR:
        return t('toolbar.tasksColorMode.options.labor');
    }
  };

  const colorOptions = [
    {
      mode: TaskColorMode.COMPANY,
      icon: IconsMap.company,
      title: 'toolbar.tasksColorMode.options.company',
    },
    {
      mode: TaskColorMode.STATUS,
      icon: IconsMap.status_new,
      title: 'toolbar.tasksColorMode.options.status',
    },
    {
      mode: TaskColorMode.LABOR,
      icon: IconsMap.worker,
      title: 'toolbar.tasksColorMode.options.labor',
      onlyInLookahead: true,
    },
  ];

  const filteredOptions = colorOptions.filter(
    (option) => !option.onlyInLookahead || viewMode === TasksViewMode.lookahead,
  );

  return (
    <CtrlBtnOption
      openOnHover
      icon={<Icon name="paint_roller" colorFill />}
      title={t('toolbar.tasksColorMode.title', 'Color all by...')}
      nestedContentPlacement="right"
      previewSelected={getTranslatedColorMode(selected)}
      nested={
        <>
          {filteredOptions.map((option) => (
            <CtrlBtnOption
              key={option.mode}
              size="s"
              selected={selected === option.mode}
              icon={<Icon name={option.icon} colorFill />}
              title={t(normalizeI18Key(option.title), option.title)}
              onClick={() => updateValue(option.mode)}
            />
          ))}
        </>
      }
    />
  );
};

export default memo(ColorTasksModeBtn);
