import {generatePath, useLocation} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import {TasksViewMode} from 'shared/constants/common';
import {ROUTES} from 'shared/constants/routes';
import {TaskStates, TaskActiveTab} from 'shared/models/task/const';
import {useRootSelector} from 'store';
import {getActiveProjectFromLocalStorage, setActiveProjectToLocalStorage} from 'store/profile/utils';

import {useCompany} from './useCompany';

export const useHandleMentionRedirects = () => {
  const location = useLocation<TasksLocationState>();
  const {profile} = useRootSelector((state) => ({
    profile: state.profile.worker,
  }));
  const company = useCompany();
  const {pathname, search} = location;
  const params = new URLSearchParams(search);
  const mentionedProjectId = location.pathname.split('/').pop();
  const taskId = params.get('id');
  const eventId = params.get('eventId');
  const isTypeIssueOrTask = params.has('type', 'task') || params.has('type', 'issue');

  if (pathname.includes('/projects/') && isTypeIssueOrTask && params.has('eventId')) {
    const isTypeTask = params.has('type', 'task');
    const newPath = generatePath(ROUTES.tasks, {projectId: mentionedProjectId});
    const searchParams = new URLSearchParams();
    if (profile && company) {
      const activeProjectId = getActiveProjectFromLocalStorage(profile.id, company.id);
      if (activeProjectId !== mentionedProjectId) {
        setActiveProjectToLocalStorage(profile.id, company.id, mentionedProjectId);
      }
      searchParams.append('state', TaskStates.active);
      searchParams.append('view', isTypeTask ? TasksViewMode.gantt : TasksViewMode.issues);
      const search = searchParams.toString();
      const redirectToMention = {
        pathname: newPath,
        search,
        state: {taskId, eventId, ...(isTypeTask ? {activeTab: TaskActiveTab.chat} : {currentIssueFocus: taskId})},
      };
      return redirectToMention;
    }
  }
  return null;
};
