import * as Sentry from '@sentry/react';
import cn from 'classnames';
import {Formik, FormikProps} from 'formik';
import {camelizeKeys} from 'humps';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery, useQueryClient} from 'react-query';
import {useHistory, useLocation, useParams} from 'react-router';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {Activity} from 'modules/Tasks/components/Gantt/types';
import {TasksLocationState} from 'modules/Tasks/types/location';
import {updateSearchQuery} from 'modules/Tasks/utils/updateSearchQuery';
import {ObserverAction, ObserverActionSource} from 'services/TasksObserver/const';
import {useTasksObserver} from 'services/TasksObserver/TasksObserverProvider';
import {useConfirm} from 'shared/components/Confirmation';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {Tab, Tabs, TabsContainer, TabsHeader} from 'shared/components/Tabs';
import {TasksViewMode} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {toShortIso} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useDistinctProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {GroupMemberRole, TaskProjection, IssueActiveTab} from 'shared/models/task/const';
import {IssueModel} from 'shared/models/task/issue';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import {useFilterContext} from '../../Filters/FilterProvider';
import {CommentsList} from '../components/CommentsTab/CommentsTimeline/CommentCard/CommentList';
import HistoryList from '../components/HistoryList/HistoryList';
import PanelSection from '../components/PanelSection/PanelSection';
import {StackOpenPanels} from '../SidebarPanel';

import IssuePanelForm from './components/IssuePanelForm/IssuePanelForm';
import {defaultFormValues, getSaveConfirmationPayload} from './constants';
import s from './IssuePanel.module.scss';
import {FormValues} from './types';
import {getChangedFields} from './utils/getChangedFields';
import {getPreparedFormValues} from './utils/getPreparedFormValues';
import {validationSchema} from './utils/validationSchema';

/* Do not delete! need for translations
 * t('common:issue', 'Issue')
 */

type IssuePanelProps = {
  onBackToParent?: () => void;
  onClose: () => void;
  stackOpenPanels?: StackOpenPanels[];
  taskId: string;
};

const IssuePanel = ({onBackToParent, onClose, stackOpenPanels, taskId}: IssuePanelProps) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation<TasksLocationState>();
  const {projectId} = useParams<{projectId: string}>();
  const project = useProjectSelector(projectId);
  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.issues.sidePanel;
  const mixpanelMeta = useMemo(
    () => Object.assign({}, {'Project Name': project?.name, 'Project ID': project?.id, place: 'issue_side_panel'}),
    [project],
  );
  const formik = useRef<FormikProps<FormValues>>();
  const containerRef = useRef<HTMLDivElement>(null);
  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);
  const {confirmAction} = useConfirm();
  const {projectWorkers} = useDistinctProjectWorkers();
  const observer = useTasksObserver();
  const {t} = useTranslation(['task', 'common']);
  const queryClient = useQueryClient();

  const updateFormikValue = (data: IssueModel) => {
    formik.current.resetForm({values: getPreparedFormValues(data)});
  };

  const getIssueInfo = async (): Promise<IssueModel | undefined> => {
    try {
      const data = await TasksApi.getTaskIssuesById(projectId, [taskId]);
      if (!data || data.length === 0) {
        throw new Error('No issue data found');
      }
      const issueData = data[0];
      updateFormikValue(issueData);
      return camelizeKeys(issueData) as IssueModel;
    } catch (error) {
      Sentry.captureException('Error fetching issue info:', error);
      return undefined;
    }
  };

  const {data: issue, isLoading} = useQuery([QUERY_CACHE_KEYS.issuesPanel, taskId], getIssueInfo, {
    enabled: !!taskId,
    onError: () => {
      toast.error(t('task:issue_panel.loading.error', 'Error loading issue.'));
    },
  });

  const maybeLoading = isLoading || loading;

  const saveIssue = async (values: FormValues) => {
    try {
      const {responsible, ...rest} = values;
      const preparedStartDate = values.startDate ? toShortIso(values.startDate) : null;
      const preparedEndDate = values.endDate ? toShortIso(values.endDate) : null;
      const preparedIssue = {
        id: issue.id,
        ...rest,
        startDate: preparedStartDate,
        endDate: preparedEndDate,
      };
      const updatedIssue = await TasksApi.updateIssue(preparedIssue);
      const changedFields = getChangedFields(preparedIssue, issue);
      mixpanel.track(mixpanelEvents.actions.saveInfo, {...mixpanelMeta, ...changedFields});
      if (responsible !== issue.responsible[0].memberId) {
        const {responsible: updatedResponsible} = await TasksApi.addIssueAssignee(projectId, issue.id, {
          memberId: responsible,
          memberRole: GroupMemberRole.responsible,
        });
        updatedIssue.responsible = updatedResponsible;
      }
      if (viewMode === TasksViewMode.issues) {
        queryClient.invalidateQueries([QUERY_CACHE_KEYS.dailiesIssues, projectId]);
        observer.emit([{data: updatedIssue}], {
          projection: TaskProjection.taskDetail,
          projectId: projectId,
          action: ObserverAction.update,
          source: ObserverActionSource.taskDetails,
        });
      } else if ('status' in changedFields) {
        // for recalculating issue badge
        observer.load({
          ids: issue.taskIds,
          projectId: project.id,
        });
      }
      queryClient.setQueryData([QUERY_CACHE_KEYS.issuesPanel, taskId], camelizeKeys(updatedIssue) as IssueModel);
      updateFormikValue(updatedIssue);
      toast.success(t('task:issue_panel.update.success', 'Issue is updated.'));
    } catch (err) {
      Sentry.captureException(err);
      toast.error(t('task:issue_panel.update.error', 'Error update issue.'));
    } finally {
      setLoading(false);
    }
  };

  const deleteIssue = async () => {
    mixpanel.track(mixpanelEvents.actions.deleteIssue);
    try {
      setLoading(true);
      await TasksApi.deleteIssue(issue.id, projectId);
      onClose();
      observer.emit([{data: {id: issue.id}}], {
        projection: TaskProjection.taskDetail,
        projectId: projectId,
        action: ObserverAction.remove,
        source: ObserverActionSource.taskDetails,
      });
    } catch (err) {
      toast.error(t('task:issue_panel.update.error', 'Error update issue.'));
    } finally {
      setLoading(false);
    }
  };

  const askToSaveBeforeLeave = (onReject: () => void, onSubmit?: () => void) => {
    return () => {
      return confirmAction(
        getSaveConfirmationPayload(t),
        formik.current?.dirty,
        onSubmit || formik.current?.handleSubmit,
        onReject,
      );
    };
  };

  const backToParentWithSubmit = async () => {
    await saveIssue(formik.current.values);
    onBackToParent();
  };

  const updateTaskIds = (ids: string[]) => {
    queryClient.setQueryData([QUERY_CACHE_KEYS.issuesPanel, taskId], (oldData: IssueModel) => ({
      ...oldData,
      taskIds: ids,
    }));
  };

  const updateActivities = (activities: Activity[]) => {
    queryClient.setQueryData([QUERY_CACHE_KEYS.issuesPanel, taskId], (oldData: IssueModel) => ({
      ...oldData,
      activities,
    }));
  };

  const onTabChanged = (tab: IssueActiveTab) => {
    history.push({pathname: location.pathname, search: updateSearchQuery(location.search, {activeTab: tab})});
  };
  const activeTab: IssueActiveTab = Number(new URLSearchParams(location.search).get('activeTab'));

  useEffect(() => {
    if (location.state?.eventId) {
      history.push({
        pathname: location.pathname,
        search: updateSearchQuery(location.search, {activeTab: IssueActiveTab.chat}),
        state: {eventId: location.state.eventId},
      });
    }
  }, [history, location.pathname, location.search, location.state?.eventId]);

  return (
    <>
      <Formik<FormValues>
        initialValues={defaultFormValues}
        onSubmit={saveIssue}
        innerRef={formik}
        validateOnChange={true}
        validationSchema={validationSchema}
      >
        {({values, submitForm, isSubmitting, dirty}) => {
          return (
            <Tabs activeTab={activeTab} onTabChanged={onTabChanged}>
              <section className={cn(s.issuePanel)} ref={containerRef}>
                <div className={s.issuePanel__viewport}>
                  <header className={s.issuePanel__header}>
                    <div className={s.issuePanel__header__actions}>
                      {stackOpenPanels?.length === 1 ? (
                        <CtrlButton
                          data-cy="panel-activity-close-tab-btn"
                          icon="clear_2"
                          color="second"
                          view="link"
                          onClick={askToSaveBeforeLeave(
                            () => mixpanel.trackWithAction(onClose, mixpanelEvents.actions.goBack, mixpanelMeta),
                            () => {
                              submitForm().then(onClose);
                            },
                          )}
                        >
                          {t('task:buttons.close', 'Close Tab')}
                        </CtrlButton>
                      ) : (
                        <CtrlButton
                          color="second"
                          view="link"
                          icon="arrow_2_left"
                          onClick={askToSaveBeforeLeave(onBackToParent, backToParentWithSubmit)}
                        >
                          {t('task:buttons.back_to_activity', 'Back to activity')}
                        </CtrlButton>
                      )}
                      {activeTab !== IssueActiveTab.chat ? (
                        <CtrlButton
                          data-cy="panel-activity-save-btn"
                          icon="check"
                          size="s"
                          onClick={submitForm}
                          disabled={
                            !dirty ||
                            !hasAnyAdminRole ||
                            isSubmitting ||
                            maybeLoading ||
                            !!issue?.timeRemoved ||
                            (issue?.status === 'archived' && values.status === TaskStatusType.archived)
                          }
                        >
                          {t('task:buttons.save', 'Save info')}
                        </CtrlButton>
                      ) : null}
                    </div>
                    <TabsHeader />
                  </header>
                  <div className={cn('panel-activity__body', s.issuePanel__body)}>
                    <TabsContainer>
                      <Tab title={t('task:tab.title.details', 'Details')}>
                        <IssuePanelForm
                          issue={issue}
                          loading={maybeLoading}
                          onDelete={deleteIssue}
                          projectId={projectId}
                          updateActivities={updateActivities}
                          updateTaskIds={updateTaskIds}
                          workers={projectWorkers}
                        />
                        <PanelSection
                          title={t('task:task_form.history', `{{type}} History`, {
                            type: t('common:issue', 'Issue'),
                          })}
                          description={!issue?.statusHistory?.length && 'History is empty for now.'}
                        >
                          <HistoryList history={issue?.statusHistory} timezone={project?.timezone} />
                        </PanelSection>
                      </Tab>
                      <Tab title={t('task:tab.title.chat', 'Comments')}>
                        <div className={s.issuePanel__comments}>
                          <CommentsList taskId={issue?.id} projectId={projectId} />
                        </div>
                      </Tab>
                    </TabsContainer>
                  </div>
                </div>
              </section>
            </Tabs>
          );
        }}
      </Formik>
    </>
  );
};

export default IssuePanel;
