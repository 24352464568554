import {VirtualElement} from '@popperjs/core';
import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {observer} from 'mobx-react-lite';
import {ComponentType, MouseEvent as RMouseEvent, ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useLocation} from 'react-router';
import {toast} from 'react-toastify';

import {DateEditor} from 'modules/Tasks/components/Gantt/components/Editors/DateEditor';
import {GanttContext} from 'modules/Tasks/components/Gantt/components/GanttContext';
import {GanttContextMenuProps} from 'modules/Tasks/components/Gantt/components/TaskContextMenu/TaskContextMenu';
import {useDetectNewTask} from 'modules/Tasks/components/Gantt/hooks/useDetectNewTask';
import {useGanttScrollPosition} from 'modules/Tasks/components/Gantt/hooks/useGanttScrollPosition';
import {useHideContextOnGanttScroll} from 'modules/Tasks/components/Gantt/hooks/useHideContextOnGanttScroll';
import {useRowsOpenState} from 'modules/Tasks/components/Gantt/hooks/useRowsOpenState';
import {ensureMinTimelineRange, useWeeksRange} from 'modules/Tasks/components/Gantt/hooks/useWeeksRange';
import {GanttComponentApi, GanttTask, LoadOptions} from 'modules/Tasks/components/Gantt/types';
import {
  disableReadonlyAfterRedo,
  GanttEventNameUnion,
  InlineEditorEventName,
  isNotEditableTask,
  isPlaceholderTask,
  KeyNavigationEventName,
  registerInlineEditorsListeners,
  startInlineEditing,
  trackRerenderingClassname,
} from 'modules/Tasks/components/Gantt/utils/gantt';
import {useIssuesDictionary} from 'modules/Tasks/hooks/useIssuesDictionary';
import {useTasksActions} from 'modules/Tasks/hooks/useTasksActions';
import {TasksLocationState} from 'modules/Tasks/types/location';
import {moveTask, restoreActivity, toggleActualizeActivity} from 'modules/Tasks/utils/asyncHelpers';
import {refreshTask} from 'modules/Tasks/utils/functions';
import {handleInitialStateParams} from 'modules/Tasks/utils/handleInitialStateParams';
import {GanttSimpleLock} from 'modules/Tasks/utils/simpleLock';
import {baselineClassNames, isBaseLineMode} from 'modules/Tasks/Views/Gantt/utils/baselineHandlers';
import {useTasksObserver} from 'services/TasksObserver/TasksObserverProvider';
import {useConfirm} from 'shared/components/Confirmation';
import {ConfirmConfig} from 'shared/components/Confirmation/useConfirm/state';
import Loader from 'shared/components/Loader';
import {useProgressReportPopupContext} from 'shared/components/ProgressReportingPopup/ProgressReportPopupProvider';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError, fetchManager} from 'shared/helpers/axios';
import {safeParseDate, startOf} from 'shared/helpers/dates';
import {debounce} from 'shared/helpers/debounce';
import {useEffectAfterMount} from 'shared/hooks/core/useEffectAfterMount';
import {useMount} from 'shared/hooks/core/useMount';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useClassName} from 'shared/hooks/useClassName';
import {useCompany} from 'shared/hooks/useCompany';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useGanttLabelCategories} from 'shared/hooks/useGanttLabelCategories';
import {useGanttSubcontractorColors} from 'shared/hooks/useGanttSubcontractorColors';
import {useGanttWeatherReport} from 'shared/hooks/useGanttWeatherReport';
import {useProfile} from 'shared/hooks/useProfile';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import {LookaheadColors} from 'shared/hooks/useResponsibleOrgColors';
import {useTasksUrlState} from 'shared/hooks/useTasksUrlState';
import Gantt from 'shared/models/Gantt';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskActiveTab, TaskObjectSubType, TaskObjectType, TaskStates} from 'shared/models/task/const';
import {TaskFilterQuery} from 'shared/models/task/filter';
import {GanttLinkModelDTO, TaskModelRawDTO, TaskGanttModel} from 'shared/models/task/task';
import {useInjectStore} from 'shared/providers/injection';
import {type GanttStoreType} from 'shared/stores/GanttStore';
import {type TasksStoreType} from 'shared/stores/TasksStore';
import {UIStoreType} from 'shared/stores/UIStore';
import {useRootDispatch} from 'store';

import {SplitStyle} from '../ActionsBar/GanttSplitDropdown';

import ProjectCalendarConvertDay from './components/ProjectCalendarConvertDay';
import s from './GanttView.module.scss';
import {useActiveTask} from './hooks/useActiveTask';
import useGanttLayoutSettings from './hooks/useGanttLayoutSettings';
import {useGanttTouchScrollListener} from './hooks/useGanttScrollListener';
import {useNavigateToMentions} from './hooks/useNavigateToMentions';
import {useTaskMentionClickHandler} from './hooks/useTaskMentionClickHandler';
import {ganttPlugins} from './plugins/ganttPlugins';
import {useGanttPlugins} from './plugins/useGanttPlugins';
import {configureTimeline, configureGanttWorkTime} from './utils/config';
import {GANTT_COLUMNS_NAMES, EditActionTypes, GANTT_LOCALE_MAP, GanttZoomLevels} from './utils/constants';
import {DataProcessorConfig, MobxDataProcessorConfig} from './utils/dataProcessors';
import {getFocusTodayDate} from './utils/date';
import {useGanttEventStore} from './utils/eventStore';
import {registerCustomKeyNavMapping, registerFrozenColumnsHandler, getOneDayFilterOptions} from './utils/functions';
import {
  watchFrozenColumnOffset,
  disableDateEditorsWhenActualized,
  onTaskCreated,
  focusEditor,
  startEdit,
  onAfterTaskDrag,
} from './utils/handlers';
import {addOrUpdateTodayMarker, addOneDayFilterMarker} from './utils/layers';

type GetActivityPanelProps = {
  taskId: string;
  onClose: () => void;
  updateActivityDetailsTask: (value: string) => void;
  gantt: GanttStatic;
};

interface GanttComponentProps {
  name: GanttNames;
  viewMode: TasksViewMode;
  projectId: string;
  noDataElement: ReactElement;
  isActiveFilter?: boolean;
  queryParams?: Partial<TaskFilterQuery>;
  lookaheadColors: LookaheadColors;
  className?: string;
  dataCy?: string;
  persistGridWidth?: boolean;
  frozenColumns?: number;
  viewPanel?: boolean;
  configure?(params: GanttComponentApi): void;
  afterInit?(params: GanttComponentApi): void;
  registerEvents?(params: GanttComponentApi): void;
  afterDpRegister?(params: GanttComponentApi): void;
  afterProjectChange?(params: GanttComponentApi): void;
  createDataProcessor?(params: DataProcessorConfig | MobxDataProcessorConfig): object;
  load(options: LoadOptions): Promise<any[]>; // Fix, make Gantt component Generic
  contextMenu?: ComponentType<GanttContextMenuProps>;
  getPanelActivity: (props: GetActivityPanelProps) => ReactElement;
}

const GANTT_WITH_FILTERS_CLASS = 'gantt-container_filtered';

const getDisableSortColumnsLabels = (labels) => [
  labels[GANTT_COLUMNS_NAMES.assignmentCount],
  labels[GANTT_COLUMNS_NAMES.taskStatus],
  labels[GANTT_COLUMNS_NAMES.subcontractor],
  labels[GANTT_COLUMNS_NAMES.type],
];

const GanttComponent = observer(function GanttComponent({
  name,
  viewMode,
  noDataElement,
  isActiveFilter,
  queryParams,
  dataCy,
  configure,
  afterProjectChange,
  afterInit,
  registerEvents,
  afterDpRegister,
  persistGridWidth,
  createDataProcessor,
  projectId,
  frozenColumns = 2,
  viewPanel = true,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  contextMenu: ContextMenu,
  getPanelActivity,
  load,
}: GanttComponentProps) {
  const project = useProjectSelector(projectId);
  const location = useLocation<TasksLocationState>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory<TasksLocationState>();
  const routes = useLocalizedRoutes();
  const [referenceSelector, setContextRef] = useState<string | VirtualElement>(null);
  const [contextMenuRef, setContextMenuRef] = useState<VirtualElement>();
  const [currentEditAction, setCurrentEditAction] = useState<EditActionTypes>(null);
  const [selectedTask, setSelectedTask] = useState<GanttTask>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const {updateCurrentTaskId} = useProgressReportPopupContext();
  const gantt = Gantt.getInstance(name);
  const simpleLock = GanttSimpleLock.getInstance(gantt);
  const {cacheHelper} = useQueryCache();
  // TODO: change to GanttTaskModel
  const [activityDetailsTask, setActivityDetailsTask] = useActiveTask(gantt);
  const containerRef = useRef<HTMLDivElement>();
  const linkToDelete = useRef<GanttLinkModelDTO>();
  const ganttDataProcessor = useRef(null);
  const clearFunctions = useRef<(() => void)[]>([]);

  const [calendarConvertDayRef, setCalendarConvertDayRef] = useState<VirtualElement>(null);
  const [calendarConvertDayDate, setCalendarConvertDayDate] = useState<Date>(null);

  const projectEventStore = useGanttEventStore<GanttEventNameUnion>(gantt);
  const commonEventStore = useGanttEventStore<GanttEventNameUnion>(gantt);
  const keyNavEventStore = useGanttEventStore<KeyNavigationEventName>(null);
  const inlineEditorsEventStore = useGanttEventStore<InlineEditorEventName>(gantt.ext.inlineEditors);
  const observer = useTasksObserver();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.gantt;
  const tasksActions = useTasksActions(gantt);
  const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
  const ganttStore = useInjectStore<GanttStoreType>(IOC_TYPES.GanttStore);
  const uiStore = useInjectStore<UIStoreType>(IOC_TYPES.UIStore);

  const tasksState = useTasksUrlState();
  const plugins = useGanttPlugins(gantt, [
    ganttPlugins.checkbox,
    ganttPlugins.contextMenu,
    ganttPlugins.copyPaste,
    ganttPlugins.statusIcon,
  ]);
  const {
    t,
    i18n: {language},
  } = useTranslation(['common', 'gantt', 'lookahead']);
  const {confirm} = useConfirm();
  useDetectNewTask(history, location, setActivityDetailsTask);
  useIssuesDictionary({gantt, projectId});
  const [getCollapsed] = useRowsOpenState(gantt, projectId, 'collapsed');
  const {trackScrollPosition, restoreScrollPosition} = useGanttScrollPosition(gantt, projectId, projectEventStore);
  const stopTasksFetchAll = useRef<() => void>();
  const profile = useProfile();
  const company = useCompany();

  const taskStateRef = useRef(tasksState);
  taskStateRef.current = tasksState;
  const {checkUserConfig, trackGridLayoutAndColumns} = useGanttLayoutSettings({
    gantt: gantt,
    eventStore: projectEventStore,
    trackGridWidth: persistGridWidth,
  });
  const dispatch = useRootDispatch();

  useClassName(document.querySelector('.screen'), 'screen--size-full');

  const range = useWeeksRange(gantt, queryParams.schedEndFirst, parseInt(queryParams.schedWeeks));

  function getApi() {
    return {gantt, eventStore: projectEventStore, cacheHelper, dataProcessor: ganttDataProcessor};
  }

  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);

  useMount(() => {
    configure?.(getApi());
    gantt.config.readonly = !hasAnyAdminRole || isScrolling;
    checkUserConfig();
    keyNavEventStore.setEventProvider(gantt.ext.keyboardNavigation);
    registerCustomKeyNavMapping(keyNavEventStore, gantt, tasksActions);
    clearFunctions.current.push(registerFrozenColumnsHandler(gantt, frozenColumns));
    gantt.init(containerRef.current);
    registerCommonGanttListeners();
    ensureMinTimelineRange(gantt);
    afterInit?.(getApi());
    clearFunctions.current.push(watchFrozenColumnOffset(gantt));
    clearFunctions.current.push(disableDateEditorsWhenActualized(gantt, t));
    registerInlineEditorsListeners(gantt, inlineEditorsEventStore, mixpanel);
    gantt.i18n.setLocale(GANTT_LOCALE_MAP[language]);
    gantt.company = company;
    gantt.workerProfile = profile;
    gantt.dRender();
    gantt.mixpanel = mixpanel;
    gantt.needReload = true;
  });

  useEffect(() => {
    gantt.config.readonly = !hasAnyAdminRole;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnyAdminRole]);

  useGanttSubcontractorColors(gantt);
  useGanttWeatherReport(gantt, project);
  useGanttLabelCategories(gantt);

  useEffect(() => {
    if (activityDetailsTask) {
      setActivityDetailsTask(null);
    }
    if (afterProjectChange) {
      return afterProjectChange(getApi());
    }
    configureTimeline(gantt, projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const updateSelectedTask = useCallback((task: GanttTask) => setSelectedTask(task), [setSelectedTask]);

  const onStopEditAction = useCallback(() => {
    setCurrentEditAction(null);
    setSelectedTask(null);
    setContextRef(null);
    setContextMenuRef(null);
  }, [setCurrentEditAction, setSelectedTask, setContextRef]);

  useHideContextOnGanttScroll(referenceSelector, gantt, onStopEditAction);

  const openTask = useCallback(
    (taskId: string, activeTab: TaskActiveTab = TaskActiveTab.info) => {
      setActivityDetailsTask(taskId);
      const isNewIssueFocus = location.state?.newIssueFocus;
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        state: {taskId, activeTab, newIssueFocus: isNewIssueFocus},
      });
    },
    [history, location.state?.newIssueFocus, setActivityDetailsTask],
  );

  const navigateToCommentTab = useCallback(
    (
      taskId: string,
      activeTab: TaskActiveTab = TaskActiveTab.chat,
      params: {dailyReportDate?: string; eventId?: string},
    ) => {
      setActivityDetailsTask(taskId);
      const newState: TasksLocationState = {
        taskId,
        activeTab,
        ...(params.eventId && {eventId: params.eventId}),
        ...(params.dailyReportDate && {dailyReportDate: params.dailyReportDate}),
      };
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        state: newState,
      });
    },
    [history, setActivityDetailsTask],
  );

  // Navigate to mentions when clicking on link in c4 chats mention (external source)
  useNavigateToMentions(gantt, navigateToCommentTab);

  // Used to open the the side panel when history icon is clicked in the ProgressReportPopup
  useEffect(
    function progressReportPopupEffect() {
      if (
        history.location?.state?.taskId &&
        history.location.state?.activeTab &&
        history.location.state?.progress &&
        gantt
      ) {
        const activityExists = gantt.isTaskExists(history.location.state.taskId);
        if (activityExists) {
          const activity = gantt.getTask(history.location.state.taskId);
          openTask(activity.id, history.location.state.activeTab);
        }
      }
    },
    [
      gantt,
      history.location.state?.activeTab,
      history.location.state?.progress,
      history.location.state?.taskId,
      openTask,
    ],
  );

  gantt.setSplitStyle = (splitStyle: SplitStyle) => {
    if (!gantt?.config?.layout?.cols?.length) {
      return;
    }

    function simulateMouse(target, eventName: string, options) {
      const ev = new MouseEvent(eventName, {
        view: window,
        bubbles: true,
        cancelable: true,
        ...options,
      });
      target.dispatchEvent(ev);
    }

    function simulateDragX(target: Element, xChange: number) {
      const rect = target.getBoundingClientRect();
      simulateMouse(target, 'mousedown', {clientX: rect.left + 4, clientY: rect.top + 5});
      simulateMouse(target, 'mousemove', {clientX: rect.left + xChange + 4, clientY: rect.top + 5});
      simulateMouse(target, 'mouseup', {clientX: rect.left + xChange + 4, clientY: rect.top + 5});
    }

    function setTableWidth(width: number) {
      const resizer = gantt.$container.querySelector('.gantt_resizer_x') as HTMLDivElement;
      const table = gantt.$container.querySelector('.gantt_layout_content') as HTMLDivElement;

      const currentWidth = table.getBoundingClientRect().width;
      const change = width - currentWidth;
      simulateDragX(resizer, change);
    }

    if (splitStyle === SplitStyle.chart) {
      let nameOnlyWidth = 0;
      for (const col of gantt.getGridColumns()) {
        nameOnlyWidth += col.width;
        if (col.name === 'name') {
          break;
        }
      }
      setTableWidth(nameOnlyWidth);
    } else if (splitStyle === SplitStyle.table) {
      setTableWidth(containerRef.current.getBoundingClientRect().width - 15);
    } else {
      const halfWidth = Math.round(containerRef.current.getBoundingClientRect().width / 2);
      const table = gantt.$container.querySelector('.gantt_grid_data') as HTMLDivElement;
      const tableSize = table.getBoundingClientRect();
      setTableWidth(Math.min(halfWidth, tableSize.width));
    }
  };

  const openIssue = (id: string) => {
    setActivityDetailsTask(id);
    history.replace({
      pathname: history.location.pathname,
      search: history.location.search,
      state: {currentIssueFocus: id},
    });
  };

  // Used to open a task when an task mention item is clicked in comments tab
  useTaskMentionClickHandler(gantt, openTask, openIssue);

  const openRisk = () => {
    mixpanel.trackWithAction(
      () => {
        const path = generatePath(routes.dailyRisk, {projectId});
        history.push({
          pathname: path,
        });
      },
      mixpanel.events.risk.reportButtonClicked,
      {viewMode},
    );
  };

  useEffectAfterMount(() => {
    const labels = gantt.getColumnLabels(t);
    gantt.config.columns.forEach((col) => {
      if (labels[col.name]) {
        if (typeof col.label === 'string' && col.label.includes('data-replacer')) {
          col.label = col.label.replace(/(data-replacer(?:=".*")?>).+(?=<)/g, `$1${labels[col.name]}`);
        } else {
          col.label = labels[col.name];
        }
      }
    });
    gantt.i18n.setLocale(GANTT_LOCALE_MAP[language]);
    gantt.dRender();
  }, [t]);

  async function loadData(showLoader = true) {
    if (showLoader) {
      setIsLoading(true);
    }
    const shouldFilter = parseInt(queryParams?.schedWeeks, 10) === 0 && viewMode === TasksViewMode.ganttVisual;
    const oneDayFilter = (tasks: TaskModelRawDTO[]) => {
      const selectedDate = dayjs(queryParams?.schedEndFirst);
      return tasks.filter((task) => task.date_list.some((date) => dayjs(date).isSame(selectedDate, 'day')));
    };

    // Reset filter to empty so subsequent filter changes will trigger GanttStore.parseTasks
    ganttStore.setFilterIds(new Set());
    try {
      load({
        projectId,
        queryParams,
        collapsed: getCollapsed(),
        dataRange: range,
        setLoading: setIsLoading,
        done: (response) => {
          // TODO Issues is completely different from other gantt views.  For now, just
          // deal with the load differently.  Issues will load actual issues, all other
          // views are driven from TasksStore ultimately, and this serves only the function
          // of determining which ids should be visible in gantt.
          if (gantt.name === GanttNames.issues) {
            gantt.parse({
              tasks: response.tasks,
            });
            gantt.needReload = false;
            gantt.callEvent('DataLoaded', undefined);
          } else if (shouldFilter) {
            const taskIds = oneDayFilter(tasksStore.tasks).map((task) => task.id);
            ganttStore.setFilterIds(taskIds);
          } else {
            ganttStore.setFilterIds(response as string[]);
          }
          // By now gantt is populated with all the tasks that are visible in the current filter,
          // and it is safe to handle taskId in the location.state, if present
          const taskId = location.state?.taskId;
          const activeTab = location.state?.activeTab ?? TaskActiveTab.info;
          const dailyReportDate = location.state?.dailyReportDate;
          const isNewIssueFocus = location.state?.newIssueFocus;
          if (taskId && gantt.isTaskExists(taskId)) {
            if (dailyReportDate && !isNewIssueFocus) {
              navigateToCommentTab(taskId, activeTab, {dailyReportDate});
            } else {
              openTask(taskId, activeTab);
              delete location.state.taskId;
            }
          }
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      extractAxiosError(error);
      setIsLoading(false);
    }
  }

  // TODO: move other listeners as needed
  function registerCommonGanttListeners() {
    /* Fill created new task */
    commonEventStore.attach('onTaskCreated', (task) => onTaskCreated(gantt, task, projectId));
    commonEventStore.attach('toolbarAction', (action) => {
      if (action === 'focusToday') {
        gantt.showDate(getFocusTodayDate());
      }
    });
  }

  const hideInlineEditor = () => {
    if (gantt.ext.inlineEditors.isVisible()) {
      gantt.ext.inlineEditors.hide();
    }
  };

  function registerGanttListeners() {
    trackRerenderingClassname(projectEventStore, gantt);
    projectEventStore.attach('onDataRender', () => focusEditor(gantt));
    projectEventStore.attach(
      'onDataRender',
      debounce(() => {
        ensureMinTimelineRange(gantt);
      }, 100),
    );
    projectEventStore.attach('onContextMenuCustom', (task: GanttTask, element: VirtualElement) => {
      if (!hasAnyAdminRole) {
        return false;
      }

      const isActivity = [TaskObjectType.activity, TaskObjectType.task].includes(task.object_type);
      const isEmptyCube = element.contextElement.classList.contains('lookahead_element_empty');
      const isTimelineArea = element.contextElement.closest('.gantt_data_area');
      // show context menu on timeline only for Lookahead view
      if (isTimelineArea && (isEmptyCube || !isActivity || viewMode !== TasksViewMode.lookahead)) {
        return false;
      }

      setContextMenuRef(element);
      return true;
    });
    projectEventStore.attach('onAfterLinkAdd', function () {
      mixpanel.track(mixpanelEvents.dragArrowDependency);
    });
    projectEventStore.attach('onBeforeRowDragMove', (_id: string, parent: string) => {
      // prevent user move activity inside another activity
      return gantt.isTaskExists(parent) ? gantt.getTask(parent).object_type !== TaskObjectType.activity : true;
    });
    /* Handle row reordering*/
    projectEventStore.attach('onBeforeRowDragEnd', function (id, parentId, index) {
      const task = gantt.getTask(id);

      // Short-circuit dropping back into original location
      if (task?.$local_index === index && String(parentId) === String(task.parent)) return false;
      const parentTask = gantt.isTaskExists(parentId) ? (gantt.getTask(parentId) as TaskGanttModel) : null;

      // Prevent actualized activities from converting into WBS
      if (parentTask && (parentTask.actual_start || parentTask.actual_end)) {
        toast.warning(
          t(
            'gantt:toast.warning.actualized_to_wbs',
            'Activity with $t(gantt:columns.actual_start) or $t(gantt:columns.actual_end) cannot become WBS',
          ),
        );
        return false;
      }

      // Prevent activities having actions from converting into WBS
      if (
        parentTask &&
        (parentTask.object_type === 'activity' || parentTask.object_type === 'task') &&
        parentTask.has_child
      ) {
        toast.warning(t('gantt:toast.warning.activity_to_wbs', 'Activity with actions cannot become WBS'));
        return false;
      }

      return true;
    });

    function gatherChildren(gantt: GanttStatic, taskId: string, allIds: Set<string>): Set<string> {
      allIds.add(taskId);
      gantt.getChildren(taskId).forEach((child) => {
        gatherChildren(gantt, child, allIds);
      });
      return allIds;
    }

    projectEventStore.attach(
      'onBeforeRowDragEnd',
      function cleanDragArguments(id: string, parent: string, tindex: number) {
        if (simpleLock.isLocked()) return false;
        const selected = gantt.getSelectedTasks();
        // getSelectedTasks has no guaranteed order.  Sort by task index
        selected.sort((a, b) => {
          const taskA = gantt.getTask(a);
          const taskB = gantt.getTask(b);
          return taskA.outline_sort_key.localeCompare(taskB.outline_sort_key);
        });

        // IFF selected count > 1 and first is WBS and all contained elements the only selection,
        // drop selection of contained elements, so it behaves as though you only moved the WBS
        if (selected?.[0] && gantt.getTask(selected[0])?.object_type === TaskObjectType.summary) {
          const idsUnderFirst = gatherChildren(gantt, selected[0], new Set());
          // if idsUnderFirst == sortedIds, can simplify move operation to drag only first id
          if (idsUnderFirst.size === selected.length && selected.every((id) => idsUnderFirst.has(id))) {
            const deselect = selected.splice(1, selected.length - 1);
            deselect.forEach((tid) => gantt.toggleTaskSelection(tid));
          }
        }

        // IFF selection still > 1 and contains mixed hierarchy levels pop warning dialog
        // saying there will be flattening
        if (selected.length > 1) {
          const levels = selected.map((taskId) => gantt.getTask(taskId).outline_sort_key.split('.').length);
          const levelCount = new Set(levels).size;
          if (levelCount > 1) {
            // warn about flattening
            confirm({
              title: t('common:confirm.move.mixed_levels.title', 'Mixed Indentation'),
              description: t(
                'common:confirm.move.mixed_levels.description',
                'Your selection has mixed indentation and will be flattened during the move.  Proceed?',
              ),
              acceptButton: t('common:confirm.move.mixed_levels.accept', 'Proceed'),
            } as ConfirmConfig).then((reply) => {
              if (reply) {
                setTimeout(() => {
                  const moveId = selected.length ? selected[0] : id;
                  gantt.moveTask(moveId, tindex, parent);
                  // call onRowDragEnd event to trigger dataProcessor
                  gantt.callEvent('onRowDragEnd', [moveId]);
                }, 50);
              }
            });

            return false;
          }
        }

        /* since gantt supports only single move, we simulate it
         to accomplish this we need to always move only first item from selection
         and set it as a first element in ids array
         rest of the selected ids would be at the end of ids array

         if user dragging not first element from selection, we ignore action
         and trigger move and save operation manually with first one
      */
        const [firstSelected] = selected;
        if (firstSelected && id !== firstSelected) {
          setTimeout(() => {
            gantt.moveTask(firstSelected, tindex, parent);
            // call onRowDragEnd event to trigger dataProcessor
            gantt.callEvent('onRowDragEnd', [firstSelected]);
          }, 50);

          return false;
        }

        return true;
      },
    );

    projectEventStore.attach('onBeforeUndo', function (action) {
      if (!action || !action.commands.length) return false;
      const stack = gantt.getRedoStack();
      if (gantt.callEvent('onBeforeUndoStack', [action]) === false) {
        return false;
      }
      stack.push(gantt.copy(action));
      while (stack.length > 10) {
        stack.shift();
      }
      for (const {value, oldValue, type, entity} of action.commands) {
        if (entity !== 'task') return;
        switch (type) {
          case 'update':
            gantt.updateTask(value.id, {...oldValue});
            break;
          case 'remove':
            restoreActivity(value.id, gantt, observer, project.timezoneOffset, oldValue.parent, oldValue.$local_index);
            break;
          case 'add':
            gantt.deleteTask(value.id);
            break;
          case 'move':
            simpleLock.run(async () => {
              await moveTask({
                taskId: value.id,
                index: oldValue.$local_index,
                parentId: String(oldValue.parent),
                gantt,
                projectId,
              });
              toast.success(t('gantt:toast.success.task_move', 'Task moved'));
            });
            break;
          default:
            Sentry.captureException(new Error('Unknown undo action type'), {
              extra: {
                type: type,
              },
            });
        }
      }
      return false;
    });

    /* Handle redo*/
    projectEventStore.attach('onBeforeRedo', function (action) {
      if (!action || !action.commands.length) return false;
      const stack = gantt.getUndoStack();
      if (gantt.callEvent('onBeforeRedoStack', [action]) === false) {
        return false;
      }
      stack.push(gantt.copy(action));
      while (stack.length > 10) {
        stack.shift();
      }

      for (const {value, type, entity} of action.commands) {
        if (entity !== 'task') return;
        switch (type) {
          case 'update':
            gantt.updateTask(value.id, {...value});
            break;
          case 'remove':
            gantt.deleteTask(value.id);
            break;
          case 'add':
            restoreActivity(value.id, gantt, observer, project.timezoneOffset);
            break;
          case 'move':
            simpleLock.run(async () => {
              await moveTask({
                taskId: value.id,
                index: value.$local_index,
                parentId: String(value.parent),
                gantt,
                projectId,
              });
              toast.success(t('gantt:toast.success.task_move', 'Task moved'));
            });
            break;
          default:
            Sentry.captureException(new Error('Unknown undo action type'), {
              extra: {
                type: type,
              },
            });
        }
      }
      return false;
    });

    /* Update dates and color for tasks after each update to show changes visually */
    projectEventStore.attach('onAfterTaskUpdate', function (id, task: GanttTask) {
      if (!isPlaceholderTask(gantt, task)) {
        refreshTask(gantt, task.id, {
          start_date: task.start_date,
          end_date: task.end_date,
        });
      }

      return true;
    });

    projectEventStore.attach('onBeforeTaskAdd', (id, task: GanttTask) => {
      /* this event may be triggered by
       *    1 manual invocation gantt.createTask()
       *    2 create task by placeholder row before it will be sent to the server
       * we need handle only second case in this callback
       * we can differ it from the first case by existing prefilled taskStatus property
       * */
      if (!isPlaceholderTask(gantt, task) && typeof id === 'number' && !task.taskStatus) {
        Object.assign(task, {
          taskStatus: TaskStatusType.assigned,
          taskDuration: 1,
          start_date: startOf(new Date(), 'day').toDate(),
          parent: gantt.getTaskByIndex(task.$index - 1)?.parent || gantt.config.root_id,
        });
        if (!task.focus && !gantt.ext.inlineEditors.isVisible()) {
          setTimeout(() => {
            startEdit(gantt, gantt.getTaskBy((task) => isPlaceholderTask(gantt, task))[0]?.id, 'name');
          }, 50);
        }
      }
    });

    projectEventStore.attach('onGridHeaderClick', function (columnName: string, e: RMouseEvent<HTMLDivElement>) {
      return !getDisableSortColumnsLabels(gantt.getColumnLabels(t)).includes((e.target as HTMLDivElement).innerText);
    });

    /* Handler single click on grid row*/
    projectEventStore.attach('onTaskClick', (taskId: string, e: MouseEvent) => {
      const {target} = e;
      if (!(target instanceof Element)) return;
      const task = gantt.isTaskExists(taskId) && gantt.getTask(taskId);
      if (!task || isNotEditableTask(task)) {
        return false;
      }

      if (target.classList.contains('gantt_tree_icon')) {
        return true;
      }

      if (target.closest('.gantt__assigners-icon')) {
        openTask(task.id, TaskActiveTab.assigners);
        mixpanel.track(mixpanel.events.gantt.inlineEdit, {column: 'watchers'});
        mixpanel.track(mixpanel.events.tasks.clickAssigneesIcon);
        return false;
      }

      if (target.closest('.gantt__actual-check')) {
        const columnName = target.closest<HTMLDivElement>('.gantt_cell')?.dataset?.columnName;
        if (columnName === 'start_date') {
          toggleActualizeActivity(task.id, GANTT_COLUMNS_NAMES.startDate, gantt);
        } else if (columnName === 'end_date') {
          toggleActualizeActivity(task.id, GANTT_COLUMNS_NAMES.endDate, gantt);
        }
        return false;
      }

      if (task.object_type === TaskObjectType.milestone) {
        const columnName = target.closest<HTMLDivElement>('.gantt_cell')?.dataset?.columnName;
        if (task.object_subtype === TaskObjectSubType.start && columnName === 'actual_end') {
          return false;
        }
        if (task.object_subtype === TaskObjectSubType.end && columnName === 'actual_start') {
          return false;
        }
      }

      const activityElement = target.closest('[data-activity-id]');
      if (activityElement) {
        const activityId = activityElement.getAttribute('data-activity-id');
        const searchParams = new URLSearchParams();
        searchParams.set('view', TasksViewMode.gantt);
        history.replace({
          pathname: history.location.pathname,
          search: `?${searchParams.toString()}`,
          state: {activeTab: TaskActiveTab.info, taskId: activityId},
        });
        return false;
      }

      if (target.closest('.gantt__task-name_open')) {
        // TODO: need to investigate why inline editor opens when we click on the icon.
        hideInlineEditor();

        if (viewMode === TasksViewMode.issues) {
          if (task.id) {
            openIssue(task.id);
          }
        } else {
          openTask(task.id);
        }
        mixpanel.track(mixpanelEvents.openSidePanel);
        return false;
      }

      if (target.closest('[data-issue-icon=true]')) {
        openIssue(task.id);
        // TODO: need to investigate why inline editor opens when we click on the icon.
        hideInlineEditor();
        mixpanel.trackWithAction(() => openIssue(task.id), mixpanelEvents.clickIssueIcon);
        return false;
      }

      if (target.closest('[data-risk-icon=true]')) {
        openRisk();
        return false;
      }

      if (target.closest('[data-edit-icon=true]')) {
        if (target.parentElement.closest('[data-column-name=progress]')) {
          mixpanel.track(mixpanelEvents.gridActivityProgressButton);
        } else {
          mixpanel.track(mixpanelEvents.gridActivityAvgLaborButton);
        }
        hideInlineEditor();
        updateCurrentTaskId(task.id);
        return false;
      }

      if (target.closest('[data-comments-icon=true]')) {
        hideInlineEditor();
        mixpanel.track(mixpanelEvents.gridActivityCommentButton, {viewMode});
        openTask(taskId, TaskActiveTab.chat);
        return false;
      }

      if (target.closest('.gantt__more-info')) {
        mixpanel.track(mixpanel.events.tasks.contextMenu.openMenu);
        gantt.contextMenu.open(e);
        return false;
      }
      // TODO: temporarily solution
      // we prevent accidental click when user clicks on the menu item in the react-select
      if (isPlaceholderTask(gantt, task)) {
        const columnName = target.closest<HTMLDivElement>('.gantt_cell')?.dataset?.columnName;
        if (columnName === GANTT_COLUMNS_NAMES.name) {
          startInlineEditing(gantt, target);
        }
        return false;
      }

      return !startInlineEditing(gantt, target);
    });

    projectEventStore.attach('onTaskOpen', (taskId: string) => {
      if (gantt.isTaskExists(taskId)) {
        openTask(taskId);
      }
    });

    /* Prevent open lightbox to create/edit task*/
    projectEventStore.attach('onBeforeLightbox', () => {
      return false;
    });

    /* After user drag/move task, we mark changed property to know what we will need to save*/
    projectEventStore.attach('onBeforeTaskChanged', (id, mode, task: TaskGanttModel) => {
      onAfterTaskDrag(gantt, id, mode, task, mixpanel);
      return true;
    });

    /* in Data processor besides linkId wee need information about taskId
     * for that purpose we store link object in ref to access it during delete flow
     * TODO: keep taskId information directly inside gantt link object
     * */
    projectEventStore.attach('onBeforeLinkDelete', (id, link) => {
      linkToDelete.current = link;
    });

    projectEventStore.attach('afterTasksImport', reloadData);

    projectEventStore.attach('onAfterRedo', (actions) => disableReadonlyAfterRedo(actions, gantt));

    projectEventStore.attach('onAfterManualUpdate', onAfterUpdate);

    projectEventStore.attach('onError', (errorMsg) => {
      Sentry.captureException(new Error(errorMsg), {tags: {ganttInnerExc: gantt.name}});
      console.error(errorMsg);
      return false;
    });

    projectEventStore.attach('onEmptyClick', () => {
      if (gantt.ext.inlineEditors.isVisible()) {
        const state = gantt.ext.inlineEditors.getState();
        const task = gantt.getTask(state.id);
        if (isPlaceholderTask(gantt, task)) {
          gantt.ext.inlineEditors.hide();
        } else {
          gantt.ext.inlineEditors.save();
        }
      }
      return false;
    });

    projectEventStore.attach('onScaleClick', (e, date) => {
      if (!hasAnyAdminRole) return;
      if (
        (gantt.ext?.zoom?.getLevels() &&
          gantt.ext.zoom.getLevels()[gantt.ext.zoom.getCurrentLevel()]?.name === GanttZoomLevels.DAY) ||
        viewMode === TasksViewMode.lookahead
      ) {
        if (!e.target.classList.contains('is-day')) {
          setCalendarConvertDayRef(null);
          return;
        }
        setCalendarConvertDayDate(safeParseDate(date));
        setCalendarConvertDayRef(e.target);
      }
    });
    projectEventStore.attach('onTaskMultiSelect', (id, checked, event) => {
      event && mixpanel.track(checked ? mixpanel.events.tasks.select : mixpanel.events.tasks.deselect);
    });
    projectEventStore.attach('selectAll', () => mixpanel.track(mixpanel.events.tasks.selectAll));
    projectEventStore.attach('deselectAll', () => mixpanel.track(mixpanel.events.tasks.deselectAll));

    registerEvents && registerEvents(getApi());
  }

  function registerDataProcessor() {
    ganttDataProcessor.current = createDataProcessor?.({
      gantt,
      project,
      profile,
      linkToDelete,
      cacheHelper,
      dispatch,
      t,
    });
    ganttDataProcessor.current?.attachEvent('onAfterUpdate', onAfterUpdate);
    afterDpRegister?.(getApi());
  }

  const onAfterUpdate = async (id, action, tid) => {
    if (action === 'inserted') {
      const _id = tid || id;
      const columnToFocus = gantt.getTask(_id)?.focus;
      if (!gantt.ext.inlineEditors.isVisible() && columnToFocus) {
        startEdit(gantt, _id, columnToFocus);
      }
    }
  };

  function unregisterDataProcessor() {
    ganttDataProcessor.current?.destructor();
    ganttDataProcessor.current = null;
  }

  function detachAllGanttEvents() {
    projectEventStore.detachAll();
  }

  function scrollToStartDate() {
    const {min_date} = gantt.getState();
    gantt.showDate(min_date);
  }

  function init() {
    addOrUpdateTodayMarker(gantt);
    addOneDayFilterMarker(gantt, getOneDayFilterOptions);
    registerGanttListeners();
    registerDataProcessor();
    gantt.refreshData();
  }

  async function reInit(projectId) {
    const staleDate = gantt.getTaskByIndex(0)?.projectId !== projectId;

    configureGanttWorkTime(gantt, project, projectId);
    scrollToStartDate();

    if (staleDate || gantt.needReload) {
      gantt.clearAll();
      init();
      await loadData();
    } else {
      init();
    }
    clearFunctions.current.push(trackGridLayoutAndColumns());
    if (!restoreScrollPosition()) {
      scrollToStartDate();
    }
    trackScrollPosition();

    handleInitialStateParams({
      history,
      dispatch,
      project,
      gantt,
      t,
    });

    gantt.needReload = false;
  }

  function reloadData() {
    tasksStore.setLoadDeleted(queryParams.state === TaskStates.deleted);
    gantt.clearAll();
    addOrUpdateTodayMarker(gantt);
    addOneDayFilterMarker(gantt, getOneDayFilterOptions);
    loadData().then(() => scrollToStartDate());
  }

  useGanttTouchScrollListener(containerRef, setIsScrolling);

  useEffectAfterMount(reloadData, [queryParams]);

  useEffect(() => {
    project && reInit(project.id);
    return () => {
      unregisterDataProcessor();
      detachAllGanttEvents();
      stopTasksFetchAll.current?.();
    };
  }, [project?.id]);

  useUnmount(() => {
    gantt.ext.inlineEditors.isVisible() && gantt.ext.inlineEditors.hide();
    plugins.clear();
    unregisterDataProcessor();
    detachAllGanttEvents();
    commonEventStore.detachAll();
    inlineEditorsEventStore.detachAll();
    gantt.deleteMarker('today');
    stopTasksFetchAll.current?.();
    clearFunctions.current.forEach((fn) => fn());
  });

  // if user clicks outside the Gantt container
  // we must save changes and close inline editor
  useMount(() => {
    const onClickHandler = (e: MouseEvent) => {
      if (gantt.ext.inlineEditors.isVisible() && !containerRef.current.contains(e.target as HTMLElement)) {
        const taskId = gantt.ext.inlineEditors.getState()?.id || '';
        const task = gantt.getTask(taskId);
        if (isPlaceholderTask(gantt, task)) {
          gantt.ext.inlineEditors.hide();
        } else {
          gantt.ext.inlineEditors.save();
        }
      }
    };
    document.body.addEventListener('mousedown', onClickHandler);
    return () => {
      document.body.removeEventListener('mousedown', onClickHandler);
    };
  });

  const onClose = () => {
    setActivityDetailsTask(null);
    history.replace({
      pathname: location.pathname,
      search: location.search.replace(/[?&]?activeTab=[^&]+/, ''),
      state: {},
    });
  };

  const renderPlaceholder = () => {
    if (!isLoading && !gantt.getVisibleTaskCount() && gantt.$grid) {
      return createPortal(<div className={s.noData}>{noDataElement}</div>, gantt.$grid);
    }
  };

  useEffect(
    function loadProjectTasks() {
      if (gantt.name === GanttNames.issues || gantt.name === GanttNames.dailies) {
        // don't run this effect for when the view is issues or dallies no need its wasteful
        return;
      }

      // when projectId changes, update the tasksStore projectId -> loads tasks
      tasksStore.setProjectId(projectId);

      return () => fetchManager.abort();
    },
    [gantt.name, projectId, tasksStore],
  );

  useEffect(
    function initSideEffects() {
      ganttStore.initSideEffects();
      return () => ganttStore.destroySideEffects();
    },
    [ganttStore],
  );

  useEffect(
    function setCollapseAccessor() {
      if (ganttStore && getCollapsed) {
        ganttStore.setCollapseAccessor(getCollapsed);
      }
    },
    [ganttStore, getCollapsed],
  );

  return (
    <div
      id="ganttRoot"
      data-cy={dataCy || `gantt-grid_${gantt.name}`}
      className={cn(`gantt-grid gantt-grid_${gantt.name}`, `gantt-grid_state-${queryParams?.state}`)}
    >
      <div className="gantt-grid__content">
        <GanttContext.Provider
          value={{
            selector: referenceSelector,
            onStopEditAction,
            selectedTask,
            updateSelectedTask,
          }}
        >
          {(isLoading || uiStore.isLoading) && <Loader />}
          <div
            className={cn(`gantt-container lookahead-view gantt-container--${gantt.name}`, {
              [GANTT_WITH_FILTERS_CLASS]: isActiveFilter,
              [baselineClassNames.container]: isBaseLineMode(),
            })}
            style={{width: activityDetailsTask && viewPanel ? 'calc(100% - 390px)' : '100%', height: '100%'}}
            ref={containerRef}
          />
          {contextMenuRef && ContextMenu && (
            <ContextMenu
              boundary={contextMenuRef}
              gantt={gantt}
              activeEntityId={activityDetailsTask}
              setActiveEntityId={setActivityDetailsTask}
            />
          )}
          {calendarConvertDayRef && (
            <ProjectCalendarConvertDay
              boundary={calendarConvertDayRef}
              gantt={gantt}
              date={calendarConvertDayDate}
              onClosePopup={() => setCalendarConvertDayRef(null)}
            />
          )}
          {currentEditAction === EditActionTypes.date && <DateEditor gantt={gantt} />}
          {viewPanel && activityDetailsTask && (
            <div className="activity-details_wrap">
              {getPanelActivity({
                taskId: activityDetailsTask,
                onClose: onClose,
                updateActivityDetailsTask: setActivityDetailsTask,
                gantt: gantt,
              })}
            </div>
          )}
        </GanttContext.Provider>
      </div>
      {renderPlaceholder()}
    </div>
  );
});

export default GanttComponent;
